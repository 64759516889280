import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "antd/dist/antd.min.css";
// import "./i18n";
import { I18nProvider, LOCALES } from "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nProvider
      locale={
        localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : LOCALES.FRENCH
      }
    >
      {" "}
      <App />{" "}
    </I18nProvider>
  </React.StrictMode>
);
