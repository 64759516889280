import React, { useEffect, useState } from "react";
// import components
import Header from "./components/Header";
// import Products from "./components/Products"; //if I remove this import it will broke the testimonial css, check reason later
import Footer from "./components/Footer";
import Loader from "./components/Loader";

import Home from "./pages/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import About from "./pages/About";
// import Hero from "./components/Hero";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import Career from "./pages/Career";

const App = () => {
  const [loading, setloading] = useState(true);

  const loadingHandler = () => {
    // console.log(loading);
    setloading(true);

    setTimeout(() => {
      setloading(false);
    }, 5000);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  });

  return (
    <BrowserRouter>
      {/* <div className="w-full max-w-[1440px] mx-auto bg-white font-bold "> */}
      <div className="w-full  mx-auto bg-white font-bold ">
        <Header loadingHandler={loadingHandler} />
        {loading ? (
          <Loader />
        ) : (
          <div className="min-h-[500px]  ">
            <Routes>
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route
                path="/home"
                element={<Home loadingHandler={loadingHandler} />}
              />
              <Route path="/about" element={<About />} />{" "}
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/career" element={<Career />} />
            </Routes>
          </div>
        )}

        {/*  */}

        {/* <Newsletter /> */}
        <Footer loadingHandler={loadingHandler} />
        <path
          class="elementor-shape-fill"
          d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
        ></path>
      </div>
    </BrowserRouter>
  );
};

export default App;
