// import icons
import { IoIosCheckmarkCircle } from "react-icons/io";
//
import sms from "./assets/img/sms.jpg";
import shortcode from "./assets/img/shortcode.jpg";
import callcenter from "./assets/img/callcenter.jpg";
//
import Avatar1Img from "./assets/img/avatar-1.png";
import Avatar2Img from "./assets/img/avatar-2.png";
import Avatar3Img from "./assets/img/avatar-3.png";

// import images
import Features1Img from "./assets/img/home.png";
import translate from "./i18n/translate";

export const common = {
  learnmore: translate(`learnmore`),
  seeall: "Voir tout",
  careertitle: translate(`career.title`),
  career: translate(`career`),
  welcome: translate(`welcome`),
  overview: translate(`overview`),
  weoffer: translate(`weoffer`),
  solution: translate(`solution`),
  overviewcontent: translate(`overview.content`),
  contactpartner: translate(`contact.partner`),
  contactquestion: translate(`contact.question`),
};
export const offerList = [
  {
    title: translate(`contact.center`),
    content: translate(`contact.center.content`),
  },
  {
    title: translate(`sms.push`),
    content: translate(`sms.push.content`),
  },
  {
    title: translate(`special.number`),
    content: translate(`special.number.content`),
  },
  {
    title: translate(`rent.call`),
    content: translate(`rent.call.content`),
  },
];
export const features = {
  welcome: translate(`welcomeUnivers`),
  image: <Features1Img />,
  title: translate(`our.value.slog`),
  subtitle: translate("univers.value", {
    path: <span className="font-bold text-primary"> UNIVERS Sarl </span>,
  }),
  // subtitle:
  //   "UNIVERS Sarl est créée en Février 2021 de la volonté de son fondateur à apporter un support de qualité aux personnes utilisant des services de finance mobile. Partant, l’entreprise a élargi son activité à d’autres secteurs, afin de soutenir une clientèle toujours plus exigeante et soucieuse de la qualité des services offerts. Nous avons pour principe fondamental de répondre efficacement aux besoins de nos clients en considérant leurs réalités et  ",
  buttonText: "Show Now",
  items: [
    {
      icon: <IoIosCheckmarkCircle className="text-primary" />,
      title: translate(`ethics`),
      subtitle: translate(`ethics.content`),
    },
    {
      icon: <IoIosCheckmarkCircle className="text-primary" />,
      title: translate(`respect`),
      subtitle: translate(`respect.content`),
    },
    {
      icon: <IoIosCheckmarkCircle className="text-primary" />,
      title: translate(`excellence`),
      subtitle: translate(`excellence.content`),
    },
  ],

  feature2: {
    // image: <Features2Img />,
    title: translate(`wedo`),
    subtitle: translate(`wedo.content`, {
      path: <span className="font-bold "> UNIVERS Sarl </span>,
    }),
  },
};

export const servicesContent = {
  title: translate("base.services"),
  subtitle: translate("base.services.sub"),

  servicesList: [
    {
      title: translate("sms.push"),
      bgImg: sms,
      content:
        "Nous vous offrons un service de qualité incluant une supervision en temps réel des envois (statut des messages ) et plusieurs types d’interco: SMPP, HTTPS, API et FT",
    },
    {
      title: translate("special.number"),
      bgImg: shortcode,
      content:
        "Nos ressources en numérotation sont reparties en deux grandes catégories : Les codes USSD et les numéros spéciaux (VERT, CLASSIQUE, surtaxé).",
    },
    {
      title: translate("contact.center"),
      bgImg: callcenter,
      content:
        "Nous disposons d’un centre de contacts omnicanal de 20 positions extensibles, utilisant l’une des meilleures technologies de traitement des appels Dinomi ... Rent a Call, une solution de traitements et de prise en charge d’appels. Nous vous offrons ainsi la possibilité d’acquérir un volume d'appels selon vos besoins",
    },
  ],
};

// export const navigation = (t) => {
//   const navigationTest = [
//     {
//       name: `${t("home")}`,
//       href: "home",
//     },
//     {
//       name: `${t("about")}`,
//       href: "about",
//     },
//     {
//       name: `${t("services")}`,
//       href: "services",
//       // children: [
//       //   {
//       //     name: "web design",
//       //     href: "home",
//       //   },
//       //   {
//       //     name: "web development",
//       //     children: [
//       //       {
//       //         name: "Frontend",
//       //         href: "home",
//       //       },
//       //       {
//       //         name: "Backend",
//       //         href: "home",
//       //         children: [
//       //           {
//       //             name: "NodeJS",
//       //             href: "home",
//       //           },
//       //           {
//       //             name: "PHP",
//       //             href: "home",
//       //           },
//       //         ],
//       //       },
//       //     ],
//       //   },
//       //   {
//       //     name: "SEO",
//       //     href: "home",
//       //   },
//       // ],
//     },

//     {
//       name: `${t("career")}`,
//       href: "career",
//     },
//     {
//       name: `${t("contact")}`,
//       href: "contact",
//     },
//     // {
//     //   name: `${t("blog")}`,
//     //   href: "blog",
//     // },
//     // {
//     //   name: "Espace client",
//     //   href: "contact",
//     // },
//     // {
//     //   name: "News",
//     //   href: "contact",
//     // },
//   ];

//   return navigationTest;
// };

export const navigation = [
  {
    name: translate("home"),
    href: "home",
  },
  {
    name: translate(`about`),
    href: "about",
  },
  {
    name: translate(`services`),
    href: "services",
    // children: [
    //   {
    //     name: "web design",
    //     href: "home",
    //   },
    //   {
    //     name: "web development",
    //     children: [
    //       {
    //         name: "Frontend",
    //         href: "home",
    //       },
    //       {
    //         name: "Backend",
    //         href: "home",
    //         children: [
    //           {
    //             name: "NodeJS",
    //             href: "home",
    //           },
    //           {
    //             name: "PHP",
    //             href: "home",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     name: "SEO",
    //     href: "home",
    //   },
    // ],
  },

  {
    name: translate(`career`),
    href: "career",
  },
  {
    name: translate(`contact`),
    href: "contact",
  },
  // {
  //   name: `${t("blog")}`,
  //   href: "blog",
  // },
  // {
  //   name: "Espace client",
  //   href: "contact",
  // },
  // {
  //   name: "News",
  //   href: "contact",
  // },
];
//  const hero = (t) => {

//   return hero;
// };

export const hero = {
  title: translate(`hero.title`),
  subtitle: translate(`hero.subtitle`),
  subtitle2: translate(`hero.subtitle2`),
  buttonText: translate(`contact.us`),
};

//

export const siteInfo = {
  phone: "(+ 237) 658928638",
  location: "Logpom face ENEO │ BP. 8557 Douala Cameroun",
  email: "univers@universsarl.com",
};
//

export const stats = [
  {
    value: "5 +",
    text: translate(`happyClients`),
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        viewBox="0 0 20 20"
        fill="#7ed956"
      >
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>
    ),
  },
  {
    value: "10 +",
    text: translate(`serivesProviding`),
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        viewBox="0 0 20 20"
        fill="#7ed956"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
  },
  {
    value: "5 +",
    text: translate(`projectCompleted`),

    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        viewBox="0 0 20 20"
        fill="#7ed956"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
        />
      </svg>
    ),
  },
  {
    value: "10 +",
    text: translate(`teamMembers`),
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        viewBox="0 0 20 20"
        fill="#7ed956"
      >
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>
    ),
  },
];

export const testimonial = {
  title: translate(`client.review`),
  citation: translate(`citation`),
  // image: <TestimonialImg />,
  persons: [
    {
      avatar: <Avatar1Img />,
      name: "Josh Smith",
      occupation: "Manager of The New York Times",
      message:
        "“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”",
    },
    {
      avatar: <Avatar2Img />,
      name: "Brandi Johns",
      occupation: "Manager of The New York Times",
      message:
        "“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”",
    },
    {
      avatar: <Avatar3Img />,
      name: "Paula Pfeffer",
      occupation: "Manager of The New York Times",
      message:
        "“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”",
    },
  ],
};
