import React from "react";

// import components
import TestimonialSlider from "./TestimonialSlider";
// import data
import { testimonial } from "../dataText";

const Testimonial = () => {
  const { title, citation } = testimonial;
  return (
    <section className="section  p-4 pb-32  rounded-lg shadow-lg">
      <div className=" relative min-h-[50vh] bg-white w-full rounded-2xl xl:w-[65%] mx-auto shadow-xl p-2 text-center">
        <p className="text-lg xl:text-5xl text-primary ">{title}</p>
        {/* <p className="xl:text-lg text-md xl:px-64">
          Lorem ipsum dolor sit amet consectetur adipiscinge lit sed do eiusm
          tempor incididunt ut labore etdolor emagna aliquan enim justo.
        </p> */}
        <div className="absolute  h-[40vh] w-[80%] bg-black text-white  left-[10%] rounded-xl bg-feedback bg-left lg:bg-center bg-cover bg-no-repeat">
          <div className="h-[40vh]  rounded-xl bg-black/75  p-2">
            <div className=" mx-auto ">
              <TestimonialSlider />

              <p className="text-center absolute bottom-0 right-2  animate-pulse xl:text-2xl text-primary m-0 ">
                {citation}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
