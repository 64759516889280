import React from "react";

// import data
import { features } from "../dataText";

const FeaturesSecond = () => {
  const { title, subtitle } = features.feature2;
  return (
    <section className="">
      <div
        id="home"
        className="header_hero bg-gray h-96 relative overflow-hidden lg:flex items-center bg-agent bg-right lg:bg-center bg-cover bg-no-repeat"
      >
        <div className="header_shape  hidden lg:block " />
        <div class="header_image flex items-center ">
          <div className="lg:px-10 text-white">
            <p className="text-5xl  font-bold">{title}</p>
            <p className="text-lg">{subtitle}</p>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSecond;
