import React from "react";
import { CgPhone, CgMail, CgPin } from "react-icons/cg";
// import { useTranslation } from "react-i18next";
import { siteInfo } from "../dataText.js";
import ChangeLng from "../i18n/ChangeLng";
import translate from "../i18n/translate.js";

export default function TopHeader() {
  // const { i18n } = useTranslation(["navbar"]);

  return (
    <>
      <div className=" bg-primary -mb-4 p-2 px-2 md:flex lg:justify-between ">
        <div className=" text-white items-center sm:flex md:mt-2  ">
          <p className=" cursor-pointer  mx-4 flex  items-center  ">
            <CgPin className="text-white text-4xl rounded-full border-2 p-0.5 border-secondary" />{" "}
            <p className="ml-2 m-0">
              {" "}
              <p className="m-0 text-secondary">
                {translate("address")} :
              </p>{" "}
              <p className="m-0">{siteInfo.location}</p>
            </p>
          </p>
          <p className="cursor-pointer  mx-4 flex items-center ">
            <CgPhone className="text-white text-4xl rounded-full border-2 p-0.5 border-secondary" />{" "}
            <p className="ml-2 m-0">
              {" "}
              <p className="m-0 text-secondary">24/7 Support</p>{" "}
              <p className="m-0">{siteInfo.phone}</p>
            </p>
          </p>
          <p className="cursor-pointer  mx-4 flex items-center ">
            <CgMail className="text-white text-4xl rounded-full border-2 p-0.5 border-secondary" />{" "}
            <p className="ml-2 m-0">
              {" "}
              <p className="m-0 text-secondary">Email:</p>{" "}
              <p className="m-0">{siteInfo.email}</p>
            </p>
          </p>
        </div>
        <ChangeLng />
      </div>
    </>
  );
}
