// import icons
import {
  IoLogoYoutube,
  IoLogoFacebook,
  IoLogoGithub,
  IoLogoInstagram,
  IoMdAddCircle,
  IoIosCheckmarkCircle,
  IoIosArrowRoundForward,
  // IoMdPhoneLandscape,
} from "react-icons/io";

// import images
import Features1Img from "./assets/img/home.png";
import Features2Img from "./assets/img/features-2.png";
import ChairImg from "./assets/img/chair.png";
import BedImg from "./assets/img/bed.png";
import CupboardImg from "./assets/img/cupboard.png";
import LightingImg from "./assets/img/lighting.png";
import Product1Img from "./assets/img/products/product-1.png";
import Product2Img from "./assets/img/products/product-2.png";
import Product3Img from "./assets/img/products/product-3.png";
import Product4Img from "./assets/img/products/product-4.png";
import Product5Img from "./assets/img/products/product-5.png";
import Product6Img from "./assets/img/products/product-6.png";
import Product7Img from "./assets/img/products/product-7.png";
import Product8Img from "./assets/img/products/product-8.png";
import Product9Img from "./assets/img/products/product-9.png";
import Product10Img from "./assets/img/products/product-10.png";
import TestimonialImg from "./assets/img/testimonial.png";
import Avatar1Img from "./assets/img/avatar-1.png";
import Avatar2Img from "./assets/img/avatar-2.png";
import Avatar3Img from "./assets/img/avatar-3.png";
import Avatar4Img from "./assets/img/avatar-4.jpg";
import Avatar5Img from "./assets/img/avatar-5.jpg";

//
import sms from "./assets/img/sms.jpg";
import shortcode from "./assets/img/shortcode.jpg";
import callcenter from "./assets/img/callcenter.jpg";
import translate from "./i18n/translate";
//

export const siteInfo = {
  phone: "(+ 237) 658928638",
  location: "Logpom face ENEO │ BP. 8557 Douala Cameroun",
  email: "infos@universsarl.com │ univers@universsarl.com",
  // email: [
  //   { title: "infos@universsarl.com" },
  //   { title: "univers@universsarl.com" },
  // ],
};
//
export const ourJobs = [
  {
    title: "Call center",
    content: [
      { title: "Responsable Centre d'appels" },
      { title: "Responsable Qualité" },
      { title: "Chef de plateau" },
      { title: "Superviseur" },
      { title: "Téléconseiller" },
    ],
  },
  {
    title: "Commercial & Marketing",
    content: [
      { title: "Sales & Marketing" },
      { title: "Manager" },
      { title: "Régional Sales Manager" },
      { title: "Key Account Manager" },
      { title: "Commerciaux" },
      { title: "Commandos" },
    ],
  },
  {
    title: "Administratif",
    content: [
      { title: "Responsable des Ressources Humaines" },
      { title: "Responsable Financier et Comptable" },
      { title: "Assistant Comptable" },
      { title: "Secrétariat de Direction" },
    ],
  },
  {
    title: "Technique",
    content: [
      {
        title:
          "Chef département des Systèmes d'Information et de l'Innovation (DSII)",
      },
      { title: "Responsable du Service VAS et Développement Produit" },
      {
        title:
          "Responsable du Service Exploitation et Service Data Analyse et reporting",
      },
      { title: "Développeurs" },
    ],
  },
];

//
export const ourTeams = [
  {
    name: "Jenifer White",
    avatar: Avatar4Img,
    occupation: "Chief Customer Officer",
  },
  {
    name: "Amelia Daniel",
    avatar: Avatar5Img,
    occupation: "Founder and CEO",
  },
  // {
  //   name: "Jack Carter",
  //   avatar: Avatar3Img,
  //   occupation: "Developer",
  // },
  // {
  //   name: "Harly Rayan",
  //   avatar: Avatar4Img,
  //   occupation: "Manager",
  // },
];

export const servicesList = [
  {
    title: translate("sms.push"),
    bgImg: sms,
    content:
      "Nous vous offrons un service de qualité incluant une supervision en temps réel des envois (statut des messages ) et plusieurs types d’interco: SMPP, HTTPS, API et FT",
  },
  {
    title: translate("special.number"),
    bgImg: shortcode,
    content:
      "Nos ressources en numérotation sont reparties en deux grandes catégories : Les codes USSD et les numéros spéciaux (VERT, CLASSIQUE, surtaxé).",
  },
  {
    title: translate("rent.call"),
    bgImg: callcenter,
    content:
      "Nous disposons d’un centre de contacts omnicanal de 20 positions extensibles, utilisant l’une des meilleures technologies de traitement des appels Dinomi ... Rent a Call, une solution de traitements et de prise en charge d’appels. Nous vous offrons ainsi la possibilité d’acquérir un volume d'appels selon vos besoins",
  },
  {
    title: translate("seo.consult"),
    bgImg: sms,
    content:
      "Lorem ipsum dolor sit amet, consetetur sadi aliquyam erat, sed diam voluptua. At vero eos accusam et justo duo dolores",
  },
  {
    title: translate("graphic.design"),
    bgImg: sms,
    content:
      "Lorem ipsum dolor sit amet, consetetur sadi aliquyam erat, sed diam voluptua. At vero eos accusam et justo duo dolores",
  },
  {
    title: translate("business.consult"),
    bgImg: sms,
    content:
      "Lorem ipsum dolor sit amet, consetetur sadi aliquyam erat, sed diam voluptua. At vero eos accusam et justo duo dolores",
  },
];
//
export const chooseUsList = [
  {
    title: "High Quality Hardware",
    content:
      "We use quality hardware, which is regularly updated to provide reliable support to your clients.",
  },
  {
    title: "24/7 Dedicated Support",
    content:
      "Our team works 24/7 to provide extensive support to your clients and partners.",
  },
  {
    title: "Time Savings with Our Consultants",
    content:
      "If you are unsure what service you need, feel free to turn to our consultants for help.",
  },
  {
    title: "Comprehensive Cost Containmenty",
    content:
      "Hiring the experts of our call center team can save a lot of costs for your business.",
  },
  {
    title: "Best Quality, Service and Price",
    content:
      "Our approach combines these three vital factors to help you achieve the maximum of success.",
  },
  {
    title: "Risk Reduction and Costs Minimization",
    content:
      "We aim to minimize your spendings on call center services while reducing all risks.",
  },
];
export const support = {
  title: "Support",
  subtitle: "Finding the the right team",
  content:
    "The href attribute requires a valid value to be accessible.Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link, use a button and change it with appropriate styles. Learn more:",

  items: [
    {
      title: "Sales",
      content: "cannot provide a valid href, but still nee",
      info: (
        <div className="cursor-pointer flex ">
          <span>Contact us</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </div>
      ),
      icon: (
        // <IoLogoFacebook className="w-16 h-16 bg-primary text-white rounded-lg mt-[-4rem]  " />
        <svg
          className="w-16 h-16 bg-primary text-white rounded-lg mt-[-4rem]
          xmlns="
          //www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
          />
        </svg>
      ),
    },
    {
      title: "Technical  support",
      content: "cannot provide a valid href, but still nee",
      info: (
        <div className="cursor-pointer flex ">
          <span>Contact us</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </div>
      ),
      icon: (
        // <IoLogoFacebook className="w-16 h-16 bg-primary text-white rounded-lg mt-[-4rem]  " />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-16 h-16 bg-primary text-white rounded-lg mt-[-4rem]  "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
      ),
    },
    {
      title: "Media",
      content: "cannot provide a valid href, but still nee",
      info: (
        <div className="cursor-pointer flex ">
          <span>Contact us</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </div>
      ),
      icon: (
        // <IoMdPhoneLandscape className="w-16 h-16 bg-primary text-white rounded-lg mt-[-4rem]  " />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-16 h-16 bg-primary text-white rounded-lg mt-[-4rem]  "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      ),
    },
  ],
};

export const navigation = [
  {
    name: `home`,
    href: "home",
  },
  {
    name: `about`,
    href: "about",
  },
  {
    name: `services`,
    href: "services",
    // children: [
    //   {
    //     name: "web design",
    //     href: "home",
    //   },
    //   {
    //     name: "web development",
    //     children: [
    //       {
    //         name: "Frontend",
    //         href: "home",
    //       },
    //       {
    //         name: "Backend",
    //         href: "home",
    //         children: [
    //           {
    //             name: "NodeJS",
    //             href: "home",
    //           },
    //           {
    //             name: "PHP",
    //             href: "home",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     name: "SEO",
    //     href: "home",
    //   },
    // ],
  },

  {
    name: `career`,
    href: "career",
  },
  {
    name: `contact`,
    href: "contact",
  },
  // {
  //   name: `${t("blog")}`,
  //   href: "blog",
  // },
  // {
  //   name: "Espace client",
  //   href: "contact",
  // },
  // {
  //   name: "News",
  //   href: "contact",
  // },
];

export const hero = {
  title: "Nos services au-delà de vos attentes",
  subtitle:
    "Vous voulez externaliser votre call center pour une prise en charge de qualité de vos client, vous voulez une installation de call enter complète avec les outils de traitement d'appels de haut niveau, vous voulez des conseils, de l'expertise pour améliorer la prise en  charge de vos client via votre call center; Vous souhaiter faire des push sms pour relancer, alerter, faire la publicité à vos clients, vous êtes revendeur de sms et vous voulez un outils adapté pour gérer des sous comptes, faire des push avancé, monitorer votre activité de sms, controller vos recharges et celles de vos clients, ... etc",
  buttonText: "Contacter nous",
};

export const stats = [
  {
    value: "5 +",
    text: "HAPPY CUSTOMERS",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        viewBox="0 0 20 20"
        fill="#7ed956"
      >
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>
    ),
  },
  {
    value: "10 +",
    text: "SERVICE PROVIDING",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        viewBox="0 0 20 20"
        fill="#7ed956"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
  },
  {
    value: "5 +",
    text: "PROJECT COMPLETED",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        viewBox="0 0 20 20"
        fill="#7ed956"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
        />
      </svg>
    ),
  },
  {
    value: "10 +",
    text: "TEAM MEMBERS",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        viewBox="0 0 20 20"
        fill="#7ed956"
      >
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>
    ),
  },
];
export const common = {
  learnmore: "Learn More",
};
export const features = {
  welcome: "Welcome to Univers",
  image: <Features1Img />,
  title: "We make your services more attractive",
  subtitle:
    "UNIVERS Sarl est créée en Février 2021 de la volonté de son fondateur à apporter un support de qualité aux personnes utilisant des services de finance mobile. Partant, l’entreprise a élargi son activité à d’autres secteurs, afin de soutenir une clientèle toujours plus exigeante et soucieuse de la qualité des services offerts. ",
  buttonText: "Show Now",
  items: [
    {
      icon: <IoIosCheckmarkCircle className="text-primary" />,
      title: "Valuation Services",
      subtitle:
        "Sometimes features require a short description.  This can be detailed description",
    },
    {
      icon: <IoIosCheckmarkCircle className="text-primary" />,
      title: "Development of Furniture Models",
      subtitle:
        "Sometimes features require a short description.  This can be detailed description",
    },
  ],
  feature2: {
    image: <Features2Img />,
    title: "What We Do",
    subtitle:
      "Univers is a leading provider of contact center and customer relationship management services throughout North America, Europe, and Asia.We possess years of demonstrable experience in delivering desired business outcomes for our clients, across industries with our best-in-class outbound, inbound and web enabled outsourcing services to improve your reputation and give your clients efficient support.",
  },
};

export const newInStore = {
  title: "New In Store Now",
  subtitle: "Get the latest items immediately with promo prices",
  link: "Check all",
  icon: <IoIosArrowRoundForward />,
  products: [
    {
      name: "chair",
      image: <ChairImg />,
    },
    {
      name: "bed",
      image: <BedImg />,
    },
    {
      name: "cupboard",
      image: <CupboardImg />,
    },
    {
      name: "lighting",
      image: <LightingImg />,
    },
  ],
};

export const products = {
  title: "All Products",
  subtitle:
    "The products we provide only for you as our service are selected from the best products with number 1 quality in the world",
  pages: [
    {
      productList: [
        {
          image: <Product1Img />,
          icon: <IoMdAddCircle />,
          name: "Ceiling Light",
          price: 75,
          oldPrice: 82,
        },
        {
          image: <Product2Img />,
          icon: <IoMdAddCircle />,
          name: "Wood Chair",
          price: 50,
          oldPrice: 70,
        },
        {
          image: <Product3Img />,
          icon: <IoMdAddCircle />,
          name: "Papper Cupboard",
          price: 105,
          oldPrice: 120,
        },
        {
          image: <Product4Img />,
          icon: <IoMdAddCircle />,
          name: "Ole Gundorse Spring",
          price: 75,
          oldPrice: 82,
        },
        {
          image: <Product5Img />,
          icon: <IoMdAddCircle />,
          name: "Treos Seroes 911",
          price: 200,
          oldPrice: 210,
        },
        {
          image: <Product6Img />,
          icon: <IoMdAddCircle />,
          name: "Multi bilderman slibber",
          price: 45,
          oldPrice: 50,
        },
        {
          image: <Product7Img />,
          icon: <IoMdAddCircle />,
          name: "XORA corner desk",
          price: 320,
          oldPrice: 325,
        },
        {
          image: <Product8Img />,
          icon: <IoMdAddCircle />,
          name: "Black Forest Series Wood",
          price: 225,
          oldPrice: 240,
        },
        {
          image: <Product9Img />,
          icon: <IoMdAddCircle />,
          name: "Papper Cupboard",
          price: 105,
          oldPrice: 120,
        },
        {
          image: <Product10Img />,
          icon: <IoMdAddCircle />,
          name: "Ole Gundorse Spring",
          price: 75,
          oldPrice: 82,
        },
      ],
    },
    {
      productList: [
        {
          image: <Product1Img />,
          icon: <IoMdAddCircle />,
          name: "Ceiling Light",
          price: 75,
          oldPrice: 82,
        },
        {
          image: <Product2Img />,
          icon: <IoMdAddCircle />,
          name: "Wood Chair",
          price: 50,
          oldPrice: 70,
        },
        {
          image: <Product3Img />,
          icon: <IoMdAddCircle />,
          name: "Papper Cupboard",
          price: 105,
          oldPrice: 120,
        },
        {
          image: <Product4Img />,
          icon: <IoMdAddCircle />,
          name: "Ole Gundorse Spring",
          price: 75,
          oldPrice: 82,
        },
        {
          image: <Product5Img />,
          icon: <IoMdAddCircle />,
          name: "Treos Seroes 911",
          price: 200,
          oldPrice: 210,
        },
        {
          image: <Product6Img />,
          icon: <IoMdAddCircle />,
          name: "Multi bilderman slibber",
          price: 45,
          oldPrice: 50,
        },
        {
          image: <Product7Img />,
          icon: <IoMdAddCircle />,
          name: "XORA corner desk",
          price: 320,
          oldPrice: 325,
        },
        {
          image: <Product8Img />,
          icon: <IoMdAddCircle />,
          name: "Black Forest Series Wood",
          price: 225,
          oldPrice: 240,
        },
        {
          image: <Product9Img />,
          icon: <IoMdAddCircle />,
          name: "Papper Cupboard",
          price: 105,
          oldPrice: 120,
        },
        {
          image: <Product10Img />,
          icon: <IoMdAddCircle />,
          name: "Ole Gundorse Spring",
          price: 75,
          oldPrice: 82,
        },
      ],
    },
  ],
};

export const testimonial = {
  title: "Our Clients Reviews",
  citation: "If we don't take care of our customers, someone else will !!!",
  // image: <TestimonialImg />,
  persons: [
    {
      avatar: <Avatar1Img />,
      name: "Josh Smith",
      occupation: "Manager of The New York Times",
      message:
        "“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”",
    },
    {
      avatar: <Avatar2Img />,
      name: "Brandi Johns",
      occupation: "Manager of The New York Times",
      message:
        "“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”",
    },
    {
      avatar: <Avatar3Img />,
      name: "Paula Pfeffer",
      occupation: "Manager of The New York Times",
      message:
        "“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”",
    },
  ],
};

export const newsletter = {
  title: "Get more discount Off your order",
  subtitle: "Join our mailing list",
  placeholder: "Your email address",
  buttonText: "Send",
};

export const footer = {
  social: [
    {
      icon: <IoLogoYoutube />,
      href: "#",
    },
    {
      icon: <IoLogoInstagram />,
      href: "#",
    },
    {
      icon: <IoLogoGithub />,
      href: "#",
    },
    {
      icon: <IoLogoFacebook />,
      href: "#",
    },
  ],
  copyright: "FurniShop 2022 - All Rights Reserved.",
};

export const DashSvg = (
  <svg
    width={235}
    height={235}
    viewBox="0 0 107 134"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="104.999"
      cy={132}
      r="1.66667"
      transform="rotate(180 104.999 132)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 104.999 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 104.999 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy={88}
      r="1.66667"
      transform="rotate(180 104.999 88)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 104.999 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy={45}
      r="1.66667"
      transform="rotate(180 104.999 45)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy={16}
      r="1.66667"
      transform="rotate(180 104.999 16)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy={59}
      r="1.66667"
      transform="rotate(180 104.999 59)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 104.999 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="104.999"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 104.999 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy={132}
      r="1.66667"
      transform="rotate(180 90.3333 132)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 90.3333 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 90.3333 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy={88}
      r="1.66667"
      transform="rotate(180 90.3333 88)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 90.3333 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy={45}
      r="1.66667"
      transform="rotate(180 90.3333 45)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy={16}
      r="1.66667"
      transform="rotate(180 90.3333 16)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy={59}
      r="1.66667"
      transform="rotate(180 90.3333 59)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 90.3333 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="90.3333"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 90.3333 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy={132}
      r="1.66667"
      transform="rotate(180 75.6654 132)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={132}
      r="1.66667"
      transform="rotate(180 31.9993 132)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 75.6654 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 31.9993 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 75.6654 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 31.9993 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy={88}
      r="1.66667"
      transform="rotate(180 75.6654 88)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={88}
      r="1.66667"
      transform="rotate(180 31.9993 88)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 75.6654 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 31.9993 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy={45}
      r="1.66667"
      transform="rotate(180 75.6654 45)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={45}
      r="1.66667"
      transform="rotate(180 31.9993 45)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy={16}
      r="1.66667"
      transform="rotate(180 75.6654 16)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={16}
      r="1.66667"
      transform="rotate(180 31.9993 16)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy={59}
      r="1.66667"
      transform="rotate(180 75.6654 59)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={59}
      r="1.66667"
      transform="rotate(180 31.9993 59)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 75.6654 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 31.9993 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="75.6654"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 75.6654 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 31.9993 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy={132}
      r="1.66667"
      transform="rotate(180 60.9993 132)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={132}
      r="1.66667"
      transform="rotate(180 17.3333 132)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 60.9993 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 17.3333 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 60.9993 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 17.3333 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy={88}
      r="1.66667"
      transform="rotate(180 60.9993 88)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={88}
      r="1.66667"
      transform="rotate(180 17.3333 88)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 60.9993 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 17.3333 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy={45}
      r="1.66667"
      transform="rotate(180 60.9993 45)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={45}
      r="1.66667"
      transform="rotate(180 17.3333 45)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy={16}
      r="1.66667"
      transform="rotate(180 60.9993 16)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={16}
      r="1.66667"
      transform="rotate(180 17.3333 16)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy={59}
      r="1.66667"
      transform="rotate(180 60.9993 59)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={59}
      r="1.66667"
      transform="rotate(180 17.3333 59)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 60.9993 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 17.3333 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="60.9993"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 60.9993 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 17.3333 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy={132}
      r="1.66667"
      transform="rotate(180 46.3333 132)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={132}
      r="1.66667"
      transform="rotate(180 2.66536 132)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 46.3333 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 2.66536 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 46.3333 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 2.66536 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy={88}
      r="1.66667"
      transform="rotate(180 46.3333 88)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={88}
      r="1.66667"
      transform="rotate(180 2.66536 88)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 46.3333 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 2.66536 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy={45}
      r="1.66667"
      transform="rotate(180 46.3333 45)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={45}
      r="1.66667"
      transform="rotate(180 2.66536 45)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy={16}
      r="1.66667"
      transform="rotate(180 46.3333 16)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={16}
      r="1.66667"
      transform="rotate(180 2.66536 16)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy={59}
      r="1.66667"
      transform="rotate(180 46.3333 59)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={59}
      r="1.66667"
      transform="rotate(180 2.66536 59)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 46.3333 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 2.66536 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="46.3333"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 46.3333 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 2.66536 1.66665)"
      fill="#7ed956"
    />
  </svg>
);

export const DashSvg2 = (
  <svg
    width={34}
    height={134}
    viewBox="0 0 34 134"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="31.9993"
      cy={132}
      r="1.66667"
      transform="rotate(180 31.9993 132)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 31.9993 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 31.9993 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={88}
      r="1.66667"
      transform="rotate(180 31.9993 88)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 31.9993 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={45}
      r="1.66667"
      transform="rotate(180 31.9993 45)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={16}
      r="1.66667"
      transform="rotate(180 31.9993 16)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy={59}
      r="1.66667"
      transform="rotate(180 31.9993 59)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 31.9993 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="31.9993"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 31.9993 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={132}
      r="1.66667"
      transform="rotate(180 17.3333 132)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 17.3333 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 17.3333 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={88}
      r="1.66667"
      transform="rotate(180 17.3333 88)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 17.3333 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={45}
      r="1.66667"
      transform="rotate(180 17.3333 45)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={16}
      r="1.66667"
      transform="rotate(180 17.3333 16)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy={59}
      r="1.66667"
      transform="rotate(180 17.3333 59)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 17.3333 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="17.3333"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 17.3333 1.66665)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={132}
      r="1.66667"
      transform="rotate(180 2.66536 132)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="117.333"
      r="1.66667"
      transform="rotate(180 2.66536 117.333)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="102.667"
      r="1.66667"
      transform="rotate(180 2.66536 102.667)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={88}
      r="1.66667"
      transform="rotate(180 2.66536 88)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="73.3333"
      r="1.66667"
      transform="rotate(180 2.66536 73.3333)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={45}
      r="1.66667"
      transform="rotate(180 2.66536 45)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={16}
      r="1.66667"
      transform="rotate(180 2.66536 16)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy={59}
      r="1.66667"
      transform="rotate(180 2.66536 59)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="30.6666"
      r="1.66667"
      transform="rotate(180 2.66536 30.6666)"
      fill="#7ed956"
    />
    <circle
      cx="2.66536"
      cy="1.66665"
      r="1.66667"
      transform="rotate(180 2.66536 1.66665)"
      fill="#7ed956"
    />
  </svg>
);
