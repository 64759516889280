import React from "react";
import { motion } from "framer-motion";
import { hero } from "../dataText";
import Stats from "./Stats";
// import { useTranslation } from "react-i18next";

const Hero = () => {
  // const { t } = useTranslation(["home", "common"]);
  const { title, subtitle, buttonText, subtitle2 } = hero;

  return (
    <section className="h-full bg-fixed max-h-[850px] w-full bg-hero bg-right lg:bg-center bg-cover bg-no-repeat text-white  relative mb-12 lg:mb-28 ">
      <div className="w-full h-[850px] p-4  bg-gradient-to-r from-black  to-transparent">
        <div className="container mx-auto mt-[200px] text-center ">
          <h1 className="text-2xl text-white lg:text-[64px] lg:leading-tight  mx-auto font-semibold mb-[30px] ">
            {title}
          </h1>
          <div className="max-w-[750px]">
            {" "}
            <h2 className="mb-[5px] lg:mb-[25px] text-left lg:text-xl text-white">
              {" "}
              {subtitle}{" "}
              <span class="animate-ping absolute inline-flex h-48 w-48 left-1 top-0 md:top-1/4  md:left-2/3 rounded-full bg-primary opacity-25"></span>
            </h2>
            <h2 className="mb-[10px] lg:mb-[35px] text-left lg:text-xl text-white">
              {" "}
              {subtitle2}{" "}
              <span class="animate-ping absolute inline-flex h-48 w-48 left-1 top-0 md:top-1/4  md:left-2/3 rounded-full bg-primary opacity-25"></span>
            </h2>
          </div>
          <motion.button
            animate={{ rotateZ: 360 }}
            className="px-[35px] lg:px-[80px] py-[9px] lg:py-[16px] mb-[160px] lg:mb-[194px] text-xl rounded-full bg-transparent border border-primary hover:bg-primary backdrop-blur-md transition"
          >
            {buttonText}
          </motion.button>
          <div className="-top-[300px] relative sm:-top-[200px] lg:-top-[310px]">
            <Stats />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
