import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";

import { LOCALES } from "./locales";
import messages from "./messages";

/*
  children will be the application and locale the language used, 
  we will wrapper the application inside this provider 
*/
const Provider = ({
  children,
  locale = LOCALES.FRENCH /* set as default value language if nothing is set*/,
}) => (
  <IntlProvider
    locale={locale} // language we gonna use
    textComponent={Fragment} // use to wrapper the all app
    messages={messages[locale]} // the content of our translate words
  >
    {children}
  </IntlProvider>
);

export default Provider;
