import { Form, Input, InputNumber, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React from "react";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const normFile = (e) => {
  console.log("Upload event:", e);

  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};
/* eslint-enable no-template-curly-in-string */

const Formulaire = () => {
  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <div className="bg-white p-2 rounded-lg shadow-lg ">
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        className=""
      >
        <Form.Item
          name={["user", "name"]}
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "email"]}
          label="Email"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "age"]}
          label="Age"
          rules={[
            {
              type: "number",
              min: 0,
              max: 99,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name={["user", "website"]} label="Lien de vos réalisation">
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "introduction"]}
          label="Quelques mots sur vous "
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="CV / Lettre de Motivation">
          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger name="files" action="/upload.do">
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: "#7ed956" }} />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <button
            htmlType="submit"
            className=" p-4 text-white bg-primary hover:text-black text-xl rounded-full w-52 ml-auto border hover:border-primary hover:bg-white backdrop-blur-md transition"
          >
            Submit
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Formulaire;
