import React from "react";
// import data
import { features, common } from "../dataText";
import { Link } from "react-router-dom";
import translate from "../i18n/translate";

const Features = ({ loadingHandler }) => {
  const { title, subtitle, image, items, welcome } = features;
  const { learnmore } = common;

  return (
    <section className="section">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row lg:gap-x-[100px] mt-28 md:mt-0">
          {/* image */}
          <div className="flex-1 order-1 ">
            <img src={image.type} alt="" />
          </div>
          {/* text */}

          <div className="flex-1 flex flex-col ">
            <div className="flex items-center ">
              <div className="h-1 w-14 bg-primary rounded-xl mr-4 "></div>
              <p className="m-0 text-primary">{welcome}</p>
            </div>
            <p className="text-[1.4rem] ">{title}</p>
            <p className="subtitle">{subtitle}</p>
            {/* items */}

            <p className="text-2xl">{translate("our.value")} </p>
            <div>
              {items.map((item, index) => {
                const { icon, title, subtitle } = item;
                return (
                  <div className="flex mb-2 lg:last:mb-0" key={index}>
                    <div className="text-2xl lg:text-3xl mr-4">{icon}</div>
                    <div>
                      <h4 className="text-base lg:text-xl font-bold mb-1">
                        {title}
                      </h4>
                      <p>{subtitle}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <Link to="/about">
              {" "}
              <button
                onClick={loadingHandler}
                className="px-[35px] my-4 py-[9px] text-black  hover:text-white  text-xl rounded-full bg-transparent border border-primary hover:bg-primary transition-all duration-700"
              >
                {learnmore}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
