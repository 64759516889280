import MenuItems from "./MenuItems";

const Dropdown = ({ children, dropdown, depthLevel }) => {
  console.log(children);
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "absolute left-full -mt-8" : "";
  return (
    <ul
      className={`absolute w-64 z-50 bg-white shadow-lg  p-2  ${dropdownClass} ${
        dropdown ? "block" : "hidden"
      } `}
    >
      {children.map((children, index) => (
        <MenuItems item={children} key={index} depthLevel={depthLevel} />
      ))}
    </ul>
  );
};

export default Dropdown;
