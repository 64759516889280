import React from "react";
import { support } from "../data";

export default function Support() {
  const { items, title, subtitle, content } = support;
  return (
    <>
      <div className="relative w-full ">
        <div className="w-full h-[700px] bg-gray-900/90 absolute">
          <img
            className="w-full h-full object-cover mix-blend-overlay"
            src="assets/images/global-support.jpg"
            alt="Header"
          />
        </div>
        <div className="max-w-[1240px] mx-auto text-white relative">
          <div className="px-4 py-12">
            <h2 className="text-3xl pt-8 text-slate-300 uppercase text-center">
              {" "}
              {title}
            </h2>
            <h3 className="md:text-5xl text-xl text-white font-bold py-6 text-center">
              {subtitle}
            </h3>
            <p className="md:text-3xl text-2xl text-slate-300">{content}</p>
          </div>
          <div className="grid  lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-6 sm:mt-20 -mt-10  mb-24 text-black ">
            {items.map((item) => (
              <div className="bg-white  rounded-xl shadow-2xl">
                <div className="p-8">
                  <div> {item.icon}</div>
                  <h3 className="font-bold text-2xl my-6">{item.title}</h3>
                  <p className="text-gray-600 text-xl">{item.content}</p>
                </div>
                <div className="bg-slate-100  pl-8 py-4">
                  <p className="flex items-center text-indigo-600">
                    {item.info}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
