import React from "react";

import { stats } from "../dataText";

const Stats = () => {
  return (
    <div className=" text-black  md:p-12 mt-20 ">
      <div className="  grid sm:grid-cols-2 lg:grid-cols-4  ">
        {stats.map((item, index) => {
          return (
            <div
              key={index}
              className="min-h-[70px] sm:col-span-1 bg-white m-2 rounded-lg shadow-lg  "
            >
              <div className="text-2xl lg:text-4xl font-semibold text-primary">
                {item.value}
              </div>
              <div className="text-base lg:text-xl font-light max-w-[110px] mx-auto">
                {item.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stats;
