import React from "react";
import Support from "../components/Support";
import { servicesList } from "../data";
import translate from "../i18n/translate";

export default function Services() {
  return (
    <>
      <div className="">
        <section className=" h-full max-h-[850px] w-full bg-services bg-right lg:bg-center bg-cover bg-no-repeat text-white  relative mb-12 lg:mb-28 ">
          <div className=" h-[650px] p-4 bg-gradient-to-r from-black w-full to-transparent">
            <div class="w-full mt-48 lg:w-1/2">
              <div class=" pt-150 lg:pt-0 ml-24">
                <h2 class=" text-2xl text-white sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl font-extrabold">
                  <span class="text-primary">{translate("ousrServices")}</span>
                </h2>
                <div className="h-2 w-24 rounded-full bg-white"></div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
        <div className="grid md:grid-cols-2 xl:grid-cols-5 lg:w-[80%] mx-auto mb-24 gap-x-2">
          <div className="col-span-3">
            <p className="text-3xl  px-3">{translate("words.abtus")}</p>
            <div className="h-2 w-24 ml-3 rounded-full bg-primary mb-5"></div>
            <div className="grid md:grid-cols-2 gap-x-4 p-2 md:p-16">
              <div>
                <p>
                  <span className="my-3 text-xl font-light">
                    {" "}
                    UNIVERS SARL Nous sommes une équipe d’experts. Nous opérons
                    dans le domaine de la téléphonie, et sommes prêts à vous
                    servir au-delà de vos attentes{" "}
                    <span className="text-primary font-bold mb-5">
                      Mise à jour des systèmes téléphoniques, Banques,
                      Assurances, Hôtels, Motels, Ecoles, etc…
                    </span>
                    <span>
                      {" "}
                      Nous vous proposons des équipements de haute et de
                      dernière technologie, ainsi que des solutions
                      sophistiquées et extensibles pour améliorer la
                      communication en interne. Configuration des systèmes
                      hybrides (nous couplons la numérique à votre système
                      analogique existant)
                    </span>
                  </span>
                </p>{" "}
              </div>
              <p className="my-3 text-xl font-light">
                {" "}
                Nous travaillons à l’évolution des solutions, nous faisons
                passer votre système analogique existant en système numérique
                avec option d’enregistrement des conversations, facturations ou
                non du temps de communications aux clients (pour les hôtels par
                exemple) etc… Nous transformons votre potentiel : Des systèmes
                vous permettant non seulement de passer des appels entre vous en
                interne mais aussi de tenir des réunions ou conférences en
                interne sans avoir besoin de vous déplacer et pas besoin de
                connexion internet.
              </p>
            </div>
          </div>
          <div className="col-span-2 hidden md:block">
            {" "}
            <img
              className="  h-full w-full rounded-lg"
              src="/assets/images/helpdesk.jpg"
              alt=""
            />
          </div>
        </div>

        {/*  */}
        <div className="text-center">
          <p className="text-primary text-lg ">{translate("how.work")} </p>
          <p className="text-4xl">{translate("check.process")}</p>
        </div>
        <div className="grid sm:grid-cols-2  lg:grid-cols-4 lg:w-[80%] mx-auto mb-8 gap-6">
          <div className="work_process">
            <div className="relative  p-2 rounded-full border-2 border-gray-500   mx-auto   w-52">
              {" "}
              <img
                className=" rounded-full h-48 w-48"
                src="/assets/images/needs.jpg"
                alt=""
              />
              <div className="load hidden border-2 border-primary absolute -bottom-[1px] -left-[3px] w-[210px] h-[210px] "></div>
              <p className="  service_load_step">
                <span className="mx-auto text-white">1</span>{" "}
              </p>
            </div>
            <div className="text-center">
              <p className="mt-10 text-xl mb-1">
                {translate("choose.service")}
              </p>
              <p className="m-0">
                Aute irure dolor reprehenderit velit esse cillum dolore fugiat
              </p>
            </div>
          </div>{" "}
          <div className="work_process">
            <div className="relative  p-2 rounded-full border-2 border-gray-500   mx-auto   w-52">
              {" "}
              <img
                className=" rounded-full h-48 w-48"
                src="/assets/images/sms.jpg"
                alt=""
              />
              <div className="load hidden border-2 border-primary absolute -bottom-[1px] -left-[3px] w-[210px] h-[210px] "></div>
              <p className="  service_load_step">
                <span className="mx-auto text-white">2</span>{" "}
              </p>
            </div>
            <div className="text-center">
              <p className="mt-10 text-xl mb-1">{translate("request.meet")}</p>
              <p className="m-0">
                Aute irure dolor reprehenderit velit esse cillum dolore fugiat
              </p>
            </div>
          </div>{" "}
          <div className="work_process">
            <div className="relative  p-2 rounded-full border-2 border-gray-500   mx-auto   w-52">
              {" "}
              <img
                className=" rounded-full h-48 w-48"
                src="/assets/images/shortcode.jpg"
                alt=""
              />
              <div className="load hidden border-2 border-primary absolute -bottom-[1px] -left-[3px] w-[210px] h-[210px] "></div>
              <p className="  service_load_step">
                <span className="mx-auto text-white">3</span>{" "}
              </p>
            </div>
            <div className="text-center">
              <p className="mt-10 text-xl mb-1">{translate("custom.plan")}</p>
              <p className="m-0">
                Aute irure dolor reprehenderit velit esse cillum dolore fugiat
              </p>
            </div>
          </div>{" "}
          <div className="work_process">
            <div className="relative  p-2 rounded-full border-2 border-gray-500   mx-auto   w-52">
              {" "}
              <img
                className=" rounded-full h-48 w-48"
                src="/assets/images/needs.jpg"
                alt=""
              />
              <div className="load hidden border-2 border-primary absolute -bottom-[1px] -left-[3px] w-[210px] h-[210px] "></div>
              <p className="  service_load_step">
                <span className="mx-auto text-white">4</span>{" "}
              </p>
            </div>
            <div className="text-center">
              <p className="mt-10 text-xl mb-1">{translate("makeit")}</p>
              <p className="m-0">
                Aute irure dolor reprehenderit velit esse cillum dolore fugiat
              </p>
            </div>
          </div>
        </div>
        {/* row */}
        <section id="services" className="">
          <div className="">
            <div className="row justify-center">
              <div className="">
                <div className=" w-full text-center pb-6">
                  <p className="text-lg text-primary text-center">
                    {translate("wedo")}
                  </p>
                  <p className="text-4xl  text-center">
                    {translate("services")}
                  </p>
                </div>{" "}
                {/* section title */}
              </div>
            </div>{" "}
            {/*  */}
            {/*  */}
            <div className="p-2  grid sm:grid-cols-2 lg:grid-cols-3  mx-auto  gap-x-8 lg:w-[75%]">
              {servicesList.map((item) => (
                <div className=" services_list ">
                  <div className="relative pb-28">
                    <img className="" src={item.bgImg} alt="" />
                    <div className="small_card_service duration-500">
                      <p className="text-xl font-bold text-primary">
                        {item.title}
                      </p>
                      <p>{item.content}</p>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </div>
            <div className="bg-primary w-full grid items-center  p-10 md:grid-cols-4 lg:px-48 ">
              <div className="col-span-3">
                <p className="text-white text-4xl m-0 mb-6">
                  Contact us if you have any questions
                </p>
                <p className="text-white text-xl m-0">
                  or are interested in establishing a partnership.
                </p>
              </div>
              <div className="mt-4  w-full">
                <button className=" p-4 text-white hover:text-black text-xl rounded-full bg-transparent border border-white hover:bg-white backdrop-blur-md transition">
                  Contact Us
                </button>
              </div>
            </div>
            {/* row */}
          </div>{" "}
          {/* container */}
        </section>
        {/*  */}
        <Support />

        {/*  */}

        <div className="h-[60vh] w-full bg-quote bg-fixed bg-right lg:bg-center bg-cover bg-no-repeat">
          <div className="bg-black/60 w-full h-full  text-center md:p-20 ">
            <div className="w-full h-full lg:w-[70%]   mx-auto pt-28 md:pt-5">
              <p className="md:text-5xl text-xl text-white ">
                Get Your Personal Quote
              </p>
              <p className="text-white md:text-3xl">
                Please contact us today if you have any question or to get your
                personal pricing plan International Line
              </p>
              <button className="bg-transparent text-white border border-white rounded-full h-[60px] hover:bg-primary hover:border-primary px-7  font-medium text-xl duration-500 transition-all">
                Call us 800-2345-6789
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
