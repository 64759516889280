import React from "react";
import OurTeams from "../components/OurTeams";
import { DashSvg, newsletter } from "../data";
import { stats } from "../dataText";
// import { CgGlobeAlt } from "react-icons/cg";
import translate from "../i18n/translate";

export default function About() {
  const { subtitle, buttonText } = newsletter;

  return (
    <div className="">
      <section className=" h-full max-h-[850px] w-full bg-about bg-right lg:bg-center bg-cover bg-no-repeat text-white  relative mb-12 lg:mb-28 ">
        <div className="w-full h-[650px] p-4 bg-gradient-to-r from-black  to-transparent ">
          <div class="w-full mt-48 lg:w-1/2">
            <div class=" pt-150 lg:pt-0 ml-24">
              <h2 class=" text-2xl text-white sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl font-extrabold">
                <span class="text-primary">{translate("about")}</span>
              </h2>
              <div className="h-2 w-24 rounded-full bg-white"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white xl:w-[80%] mx-auto ">
        <div className="gap-16 items-center py-8 px-4  lg:grid lg:grid-cols-2 lg:py-16">
          <div className="relative m-2">
            <div className="border-b-2 border-r-2 border-primary absolute top-4 left-4 h-full  w-full"></div>
            <div className="  font-light text-gray-500 sm:text-lg border-2 border-primary p-12  ">
              <h2 className="mb-4 text-4xl font-extrabold text-gray-900 ">
                {translate("aboutus")}
              </h2>
              <p className="mb-4">{translate("aboutus.content")}</p>
              {/* <p>
                We are strategists, designers and developers. Innovators and
                problem solvers. Small enough to be simple and quick.
              </p> */}
            </div>
          </div>
          <div className="grid sm:grid-cols-2 gap-4 mt-8">
            <img
              className="w-full rounded-lg"
              src="/assets/images/pdg.jpg"
              alt="office content 1"
            />
            <img
              className="mt-4 w-full lg:mt-10 rounded-lg"
              src="/assets/images/about_1.jpg"
              alt="office content 2"
            />
          </div>
        </div>
      </section>

      <div className="grid md:grid-cols-2 xl:w-[85%] mx-auto ">
        <div className=" ">
          <div className=" ">
            <img src="assets/images/proud.svg" alt="about" />
          </div>
        </div>
        <div className="">
          <div className="row justify-end">
            <div className="w-full ">
              <div className="about_content mx-4 pt-11 lg:pt-15 lg:pb-15">
                <div className="section_title pb-4">
                  <h4 className="mb-4 text-lg font-extrabold text-primary ">
                    {translate("whyus")}
                  </h4>

                  <h1 className="text-4xl">{translate("goal")}</h1>
                </div>{" "}
              </div>{" "}
              <div className="">
                <div className="flex items-center">
                  <p class=" p-2 text-white  mr-2 bg-primary rounded-full w-10 h-10  ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </p>
                  <h1 class="text-2xl font-semibold text-gray-700 capitalize ">
                    {translate("mission")}
                  </h1>
                </div>
                <p className="  p-2 rounded-lg">
                  {translate("mission.content")}
                </p>
              </div>
              <div className="">
                <div className="flex items-center">
                  <p class=" p-2 text-white  mr-2 bg-primary rounded-full w-10 h-10  ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </p>
                  <h1 class="text-2xl font-semibold text-gray-700 capitalize ">
                    {translate("engagement")}
                  </h1>
                </div>
                <p className=" p-2 rounded-lg">
                  {translate("engagement.content")}
                </p>
              </div>
              <div className="">
                <div className="flex items-center">
                  <p class=" p-2 text-white  mr-2 bg-primary rounded-full w-10 h-10  ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </p>
                  <h1 class="text-2xl font-semibold text-gray-700 capitalize ">
                    {translate("vision")}
                  </h1>
                </div>
                <p className="  p-2 rounded-lg">
                  {translate("vision.content")}
                </p>
              </div>
              <div className="">
                <div className="flex items-center">
                  <p class=" p-2 text-white  mr-2 bg-primary rounded-full w-10 h-10  ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </p>
                  <h1 class="text-2xl font-semibold text-gray-700 capitalize ">
                    {translate("promise")}
                  </h1>
                </div>
                <p className="  p-2 rounded-lg">
                  {translate("promise.content")}
                </p>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/*  */}
      <div className="bg-concentrated  bg-left lg:bg-center bg-cover bg-no-repeat">
        <div className="grid   bg-gray-700 opacity-80  ">
          <div className=" lg:w-[80%] mx-auto grid md:grid-cols-2">
            <div className=" md:px-[25%] px-[5%] items-center flex ">
              <p className="font-light text-white text-xl">
                <p className="text-5xl text-white">{translate("ourstats")}</p>
                {/* Some heuristics on CSS that you can run on any site. Started
                with the sharing of that data from several popular sites. It’s
                open source and I would think the most valuable feedback to give
                would be “what data would be useful to know about your CSS?” */}
              </p>
            </div>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 py-[15vh] ">
              {stats.map((item) => (
                <div className="p-2 ">
                  <div className="cursor-pointer single_services text-center ">
                    <div className="services_icon">{item.icon}</div>
                    <div className="  ">
                      <h3 className="  text-white m-0 font-semibold text-xl md:text-2xl lg:text-xl xl:text-3xl">
                        {item.value}
                      </h3>
                      <p className=" text-white m-0">{item.text}</p>
                    </div>
                  </div>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* nice content commented, don't delete without check */}
      {/* <div className="xl:w-[70%] my-28 mx-auto">
        <div className=" xl:px-48">
          <p className="text-4xl">Call Center Solutions</p>
          <div className="grid items-center sm:grid-cols-2 my-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscinge lit sed do
              eiusm tempor incididunt ut labore etdolor emagna aliquan enim
              justo.
            </p>
            <button className=" p-4 text-white bg-primary hover:text-black text-xl rounded-full w-52 ml-auto border hover:border-primary hover:bg-white backdrop-blur-md transition">
              Read More
            </button>
          </div>
        </div>
        <div className=" grid lg:grid-cols-3 sm:grid-cols-2 gap-4 xl:px-48 ">
          <div className=" bg-white shadow-lg p-8 hover:border-l-4 hover:border-primary hover:scale-105 cursor-pointer duration-300    rounded-2xl ">
            <div className="flex items-center mb-6">
              <div className=" h-14 w-14 rounded-full mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-full w-full text-primary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>
              <p className="m-0 text-lg">Experienced & Qualified Staff</p>
            </div>
            <p className="m-0">
              Lorem ipsum dolor sit amet consectet uradipisc ing elit sed do
              eiusm temp orinc ididuquis enimone.
            </p>
          </div>{" "}
          <div className=" bg-white shadow-lg p-8 hover:border-l-4 hover:border-primary hover:scale-105 cursor-pointer duration-300   rounded-2xl ">
            <div className="flex items-center mb-6">
              <div className=" h-14 w-14 rounded-full mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-full w-full text-primary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>
              <p className="m-0 text-lg">24/7 Customer Support</p>
            </div>
            <p className="m-0">
              Lorem ipsum dolor sit amet consectet uradipisc ing elit sed do
              eiusm temp orinc ididuquis enimone.
            </p>
          </div>{" "}
          <div className=" bg-white shadow-lg p-8 hover:border-l-4 hover:border-primary hover:scale-105 cursor-pointer duration-300   rounded-2xl ">
            <div className="flex items-center mb-6">
              <div className=" h-14 w-14 rounded-full mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-full w-full text-primary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                  />
                </svg>
              </div>
              <p className="m-0 text-lg">Foreign Language Service</p>
            </div>
            <p className="m-0">
              Lorem ipsum dolor sit amet consectet uradipisc ing elit sed do
              eiusm temp orinc ididuquis enimone.
            </p>
          </div>
        </div>
      </div> */}
      {/*  */}

      {/* <div className="lg:w-[70%] mx-auto grid md:grid-cols-2 my-28">
        <div>
          <p className="text-5xl text-primary">
            We Are Building a Customer Base!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscinge lit sed do eiusm
            tempor incididunt ut labore etdolor emagna aliquan enim justo.
          </p>
          <div>
            <div className="grid sm:grid-cols-2 gap-4 my-8">
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="m-0">Safe & Trusted</p>
              </div>{" "}
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="m-0">Call Notification</p>
              </div>{" "}
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="m-0">Fast Response</p>
              </div>{" "}
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="m-0">Improve Speech</p>
              </div>{" "}
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="m-0">Improve Speech</p>
              </div>{" "}
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="m-0">Report Analytics</p>
              </div>
            </div>
            <button className=" p-4 my-4 text-white bg-primary hover:text-black text-xl rounded-full w-52 ml-auto border hover:border-primary hover:bg-white backdrop-blur-md transition">
              Discover now!
            </button>
          </div>
        </div>
        <div className="relative bg-needs h-[30rem] border-4 border-primary rounded-xl ">
          <div className="flex items-center bg-white w-60 rounded-xl absolute bottom-24 md:-left-24 shadow-xl p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 text-white"
              fill="#7ed956"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div>
              <p className="text-2xl m-0">1281 +</p>
              <p className="m-0">New Customers</p>
            </div>
          </div>
          <div className="absolute h-20 w-20  border-white border-2 top-20 -left-2 rounded-full">
            <img
              class="rounded-full"
              alt="A"
              src="https://randomuser.me/api/portraits/men/32.jpg"
            />
          </div>
          <div className="absolute h-20 w-20  border-white border-2 bottom-10 right-10 rounded-full">
            <img
              class="rounded-full"
              alt="A"
              src="https://randomuser.me/api/portraits/women/68.jpg"
            />
          </div>
          <div className="absolute h-20 w-20  border-white border-2 bottom-10 left-16 rounded-full">
            <img
              class="rounded-full"
              alt="A"
              src="https://randomuser.me/api/portraits/men/60.jpg"
            />
          </div>{" "}
          <div className="absolute h-20 w-20  border-white border-2 bottom-[50%] left-[33%] rounded-full">
            <img
              class="rounded-full"
              alt="A"
              src="https://randomuser.me/api/portraits/women/66.jpg"
            />
          </div>
          <div className="absolute h-20 w-20  border-white border-2 right-0 top-0 rounded-full">
            <img
              class="rounded-full"
              alt="A"
              src="https://randomuser.me/api/portraits/women/55.jpg"
            />
          </div>
        </div>
      </div> */}

      {/* <OurTeams /> */}

      {/* new letters */}
      {/* <div className="relative  lg:w-[70%] mx-auto text-center my-20 py-20">
        <p className="text-5xl">Join Our Newsletter</p>
        <p className="text-xl ">
          JLorem ipsum dolor sit amet consectetur adipiscinge lit sed do eiusm
          tempor incididunt ut labore etdolor emagna aliquan enim justo.
        </p>
        <div className=" grid items-center  mb-6 ">
          <div className="w-full max-w-[558px] text-center  text-white mb-6  mx-auto">
            <div className="flex flex-col lg:flex-row gap-[22px]">
              <input
                className=" h-[60px] px-6 outline-none placeholder:text-gray-400 text-gray-600 rounded-lg lg:flex-1"
                type="email"
                placeholder={subtitle}
              />
              <button className="bg-primary border  h-[60px] hover:bg-white hover:text-black px-7 rounded-lg font-medium text-xl transition-all">
                {buttonText}
              </button>
            </div>
          </div>
        </div>
        <span className="absolute top-0 right-0">{DashSvg} </span>
        <span className="absolute bottom-0 left-0">{DashSvg} </span>
      </div> */}
    </div>
  );
}
