import React from "react";
import { FormattedMessage } from "react-intl";

const translate = (id, value = {}) => (
  <FormattedMessage
    id={id} // key of the word to translate
    values={{ ...value }}
    /* 
      value parameter take element we don't need to change... ("word_id":"this element {path} doesn't change ");
      how to use it : translate("word_id", {path:<code> {children}  </code>})
    */
  />
);

export default translate;

//

const storageLng = localStorage.getItem("lang");

export function handleChangeLanguage(value) {
  //function to change language from localStorage
  if (value === storageLng) return;
  if (value !== "en" && value !== "fr") return;
  localStorage.setItem("lang", value);
  // setLanguage(value);
  window.location.reload();
}
