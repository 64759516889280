import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    // common usage
    home: "Home",
    cart: "Cart",
    whitelist: "Whitelist",
    account: "Account",
    "contact.us": "Contact us",
    "our.value": "Our values",
    "our.value.slog": "We make your services unique and more attractive",

    learnmore: "Read more",
    welcome: "Welcome",
    weoffer: "What we offer you",
    solution: "Our Solutions",
    workwith: "We work with the best partners",
    ourservices: "Our services",
    uselinks: "Useful links",
    connectus: "Get connected with us",
    address: "Address",
    emailus: "Email us",
    callus: "Call us",
    aboutus: "About us",

    // home
    "hero.title": "Our services beyond your expectations",
    "hero.subtitle":
      "Do you want to outsource your call center for quality customer care?  Do you need a complete call center installation with high level call processing tools? Do you need advice and expertise on how to improve your customer care via your call center? Do you want to make push sms to remind, alert and advertise to your customers?  Do you want to be an SMS reseller? Do you want an adapted tool to manage sub-accounts, make advanced pushes, ensure the monitoring of your SMS activity and control your recharges and those of your customers?",
    "hero.subtitle2":
      " If yes, Univers Sarl is a company specialized in the field of telephony with a team of dynamic and experienced experts ready to serve you beyond your needs and expectations.",
    "univers.value":
      "{path} is a company specialized in call center created in February 2021 with the aim of providing excellent quality service to people using mobile finance services. The company has also expanded its activity to other sectors in order to support a clientele increasingly demanding and concerned about the quality of services offered. Our fundamental principle is to respond effectively to the needs of our customers by taking into account their needs and expectations.",
    ethics: "Ethics",
    "ethics.content":
      "Absolute moral values on which we base our activity (loyalty to customers and employees, high quality in all circumstances)",
    respect: "Respect",
    "respect.content": "Respect for customers, laws, resources and rules",
    excellence: "Excellence",
    "excellence.content":
      "Excellence is reflected in the fact that we offer our customers a reliable and efficient quality service and in the rigorous selection of our human, software and hardware resources", // navbar
    home: "Home",
    about: "About",
    services: "Our Services",
    contact: "Contact",
    blog: "Blog",
    career: "Career",
    wedo: "What we do",
    "wedo.content":
      "{path} is a company specialized in call center located in the city of Douala. It offers a service of help and assistance to customers before, during and after the purchase act. We ensure the centralization of all information on the customer journey to improve internal collaboration. We also provide tailored and personalized responses to each contact person in contact with your company",
    "career.title": "Would you like to develop your talent with us?",
    "overview.content":
      "We are a reliable and growing call center that works with clients in Cameroon and internationally. We offer professional services such as data enrichment, direct email marketing, lead generation, market research and appointment setting to help businesses grow and achieve their goals.  Other services we offer are inbound and outbound calling, direct response, fundraising, social media management, web development, call scoring, data entry, email marketing, surveys and live chat... We can also add value to your business, whether it's a small business with a small budget or a medium to large business. We offer flexible and customized pricing plans that are guaranteed to work for your business)",
    overview: "Overview",
    "contact.center.content":
      "We have an omnichannel contact center with 20 scalable positions, using one of the best Dinomi call processing technologies ...",
    "contact.center": "•	Contact centers",
    "sms.push": "•	SMS (Push sms)",
    "sms.push.content":
      "We offer you a quality service including a real time supervision of the sendings (status of the messages) and several types of interco: SMPP, HTTPS, API and FTP",
    "special.number": "•	Special numbers (short / long)",
    "special.number.content":
      "Our numbering resources are divided into two main categories: USSD codes and special numbers (GREEN, CLASSIC)",
    "rent.call": "call center",
    "rent.call.content":
      "A solution for processing and handling calls. We offer you the possibility to acquire a volume of calls according to your needs",
    "contact.question": "Contact us if you have any questions",
    "contact.partner": "Or are interested in establishing a partnership.",
    "client.review": "Our Clients Reviews",
    citation: "If we don't take care of our customers, someone else will !!!",

    // stats
    "happy.client": "HAPPY CUSTOMERS",
    "service.provide": "SERVICE PROVIDING",
    "project.completed": "PROJECT COMPLETED",
    "teams.members": "TEAM MEMBERS",

    // about

    "aboutus.content":
      "UNIVERS Sarl is a company specialized in the call center created in February 2021 with the aim of providing an excellent quality service to people using mobile finance services. The company has also extended its activity to other sectors in order to support an ever more demanding clientele concerned about the quality of the services offered. Our fundamental principle is to respond effectively to the needs of our customers by taking into account their needs and expectations",
    whyus: "Why Choose Us",
    goal: "Your Goal is Our Achievement",
    mission: "Mission",
    "mission.content":
      "Design omnichannel customer relationship management solutions that enable organizations to support their customers with warmth and professionalism.",
    engagement: "Commitment",
    "engagement.content":
      "UNIVERS is a team of professionals who cater to customers who get the full potential out of their innovative solutions with confidence and satisfaction.",

    vision: "Our vision",
    "vision.content": "To be the leader in customer relations in Africa.",
    promise: "Promise",
    "promise.content": "We make your customers' lives easier.",
    ourstats: "Our stats",
    //
    support: "24/7 support",
    Language: "Language",
    home: "Home",
    ousrServices: "Our Services",
    career: "Career",
    happyClients: "HAPPY CUSTOMERS",
    serivesProviding: "SERVICE DELIVERY",
    projectCompleted: "COMPLETED PROJECTS",
    teamMembers: "TEAM MEMBERS",
    welcomeUnivers: "Welcome to the UNIVERS SARL website",
    specialNumber: "Special numbers (short/long)",
    "contact.center": "Contact Center",
    "seo.consult": "Social Media Optimization Consulting",
    "graphic.design": "Infographic",
    "business.consult": "Business consulting",
    connectwithus: "Connect with us",
    address: "Address",
    "right.reserved": "All rights reserved",
    support: "Assistance",
    "find.teams": "Finding the right team",
    "more.info": "For more information, contact us",
    "ours.jobs": "Our jobs",
    "our.location": "Our location",
    phone: "Phone number",
    "email.address": "E-mail address",
    "contact.form": "Contact form",
    "y.name": "Your name",
    "y.email": "Your e-mail address",
    "y.phone": "Your phone",
    "y.msg": "Your message",
    "send.msg": "Send a message",
    "base.services": "Basic services",
    "base.services.sub":
      "UNIVERS Sarl offers a service which supports the accompaniment of the customer at each stage of the sale for a quality experience",
    "overview.subt": "Univers Call Center Company",
    "words.abtus": "A few words about us",
    "how.work": "HOW DOES IT WORK?",
    "check.process": "Check out our work process!",
    "choose.service": "Choose a service",
    "request.meet": "Request a meeting",
    makeit: "Let’s Make It Happen",
    "custom.plan": "Receive Custom Plan",
    "seo.consult": "Social Media Optimization Consulting",
  },
};
