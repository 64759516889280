import React from "react";
import { CgGlobeAlt } from "react-icons/cg";
import { Dropdown } from "antd";
import { handleChangeLanguage } from "./translate";

const ChangeLng = () => {
  const storageLng = localStorage.getItem("lang");

  const menu = (
    <div className="bg-white ">
      <p
        onClick={() => handleChangeLanguage("fr")}
        className="m-0 hover:bg-primary cursor-pointer w-full h-full p-2 text-center hover:text-white"
      >
        Français
      </p>
      <p
        onClick={() => handleChangeLanguage("en")}
        className="m-0 hover:bg-primary cursor-pointer w-full h-full p-2 text-center hover:text-white"
      >
        Anglais
      </p>
    </div>
  );
  return (
    <>
      <div className="cursor-pointer  mt-2 text-center ">
        <Dropdown
          overlay={menu}
          // trigger={["click"]}
          arrow={true}
          placement="bottomCenter"
        >
          <p className="cursor-pointer mx-4 text-white flex items-center">
            <CgGlobeAlt className="text-white text-4xl rounded-full border-2 p-0.5 border-secondary" />
            <p className="ml-2 m-0">
              {" "}
              <p className="m-0 text-secondary">Language :</p>{" "}
              <p className="m-0">
                {storageLng === "fr" ? "Français" : "English"}
              </p>
            </p>
          </p>
        </Dropdown>
      </div>
    </>
  );
};

export default ChangeLng;
