import React, { useState, useRef, useEffect } from "react";
import Dropdown from "./Dropdown";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

export default function MenuItems({ item, bg, depthLevel, loadingHandler }) {
  let ref = useRef();
  const [dropdown, setDropdown] = useState(false);
  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);
  return (
    <>
      <li ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {item.children?.length > 0 && (
          <>
            {" "}
            <button
              type="button"
              className=""
              aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              onClick={() => setDropdown((prev) => !prev)}
            >
              <p
                className={`capitalize  text-lg cursor-pointer font-semibold mr-2 ${
                  // if bg is true
                  bg
                    ? "text-white"
                    : // if bg is false
                      "text-black border-primary"
                }  hover:border-b-2 transition-all`}
              >
                {depthLevel > 0 ? (
                  <span className="flex text-black ">
                    {item.name}
                    <IoIosArrowForward className="mt-2" />
                  </span>
                ) : (
                  <span className="flex justify-between text-black">
                    <Link
                      className="text-black hover:text-black"
                      to={item.href}
                    >
                      {item.name}
                    </Link>
                    <IoIosArrowDown className="mt-2 ml-2" />
                  </span>
                )}
              </p>
            </button>
            <Dropdown
              children={item.children}
              dropdown={dropdown}
              depthLevel={depthLevel}
            />
          </>
        )}
        {!item.children && (
          <p
            className={`capitalize text-lg cursor-pointer  font-semibold ${
              // if bg is true
              bg
                ? "text-white"
                : // if bg is false
                  "text-white border-primary"
            }  hover:border-b-2 transition-all`}
            onClick={loadingHandler}
          >
            <Link className="text-white hover:text-white" to={item.href}>
              {item.name}
            </Link>
          </p>
        )}
      </li>
    </>
  );
}
