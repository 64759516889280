import React, { useState, useEffect } from "react";
import Logo from "../assets/img/logo.png";
import { CgMenuRight, CgClose } from "react-icons/cg";
import { navigation } from "../dataText";
import NavMobile from "./NavMobile";
import MenuItems from "./MenuItems";
// import i18next from "i18next";
// import { useTranslation } from "react-i18next";
import TopHeader from "./TopHeader";
import { Link } from "react-router-dom";

const Header = ({ loadingHandler }) => {
  // const { t } = useTranslation(["navbar"]);

  //
  const [bg, setBg] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);

  useEffect(() => {
    // add event listener
    window.addEventListener("scroll", () => {
      // when scrollY is bigger than 50px setBg to true, else false
      return window.scrollY > 50 ? setBg(true) : setBg(false);
    });
  });
  // useEffect(() => {
  //   if (localStorage.getItem("i18nextLng")?.length > 2) {
  //     i18next.changeLanguage("en");
  //   }
  // }, []);

  return (
    <div className="">
      {" "}
      <TopHeader />
      <header
        className={`${
          // if bg is true
          bg
            ? "bg-primary py-4 lg:py-2 top-0"
            : // if bg is false
              "bg-none"
        }
      fixed left-0    py-1 z-10 w-full transition-all duration-200`}
      >
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            {/* logo */}
            <div className=" bg-white p-1.5 rounded-full">
              <Link to="/">
                <img className="h-14 lg:h-16" src={Logo} alt="" />
              </Link>
            </div>
            {/* menu icon */}
            <div
              onClick={() => setMobileNav(!mobileNav)}
              className="md:hidden text-2xl lg:text-3xl text-white cursor-pointer"
            >
              {mobileNav ? <CgClose /> : <CgMenuRight />}
            </div>
            {/* nav */}
            <nav className="hidden md:flex mt-6  ">
              <ul className="md:flex md:gap-x-12">
                {navigation.map((item, index) => {
                  const depthLevel = 0;
                  return (
                    <MenuItems
                      key={index}
                      item={item}
                      bg={bg}
                      depthLevel={depthLevel}
                      loadingHandler={loadingHandler}
                    />
                  );
                })}
              </ul>
            </nav>
            {/* nav mobile */}
            <div
              className={`${
                mobileNav ? "left-0" : "-left-full"
              } md:hidden fixed bottom-0 w-full max-w-xs h-screen transition-all`}
            >
              <NavMobile />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
