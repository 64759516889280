import { LOCALES } from "../locales";

export default {
  [LOCALES.FRENCH]: {
    // common usage
    home: "Accueil",
    cart: "Panier",
    whitelist: "Favoris",
    account: "Compte",
    "contact.us": "Contactez-nous",
    "our.value": "Nos valeurs",
    "our.value.slog": "Nous rendons vos services uniques et  plus attrayants",
    learnmore: "En savoir plus",
    welcome: "Bienvenue",
    solution: "Nos solutions",
    weoffer: "Ce que nous vous proposons",
    workwith: "Nous travaillons avec les meilleurs partenaires",
    ourservices: "Nos services",
    uselinks: "Liens utiles",
    address: "Adresse",
    emailus: "Envoyez-nous un e-mail",
    callus: "Appelez-nous",
    connectus: "Se connecter avec nous",
    aboutus: "A propos de nous",
    // home
    "hero.title": "Nos services au-delà de vos attentes",
    "hero.subtitle":
      "Voulez-vous externaliser votre centre d’appels (call center)  pour une prise en charge de qualité de vos clients?  Avez-vous besoin d’une installation de call center complète avec les outils de traitement d'appels de haut niveau? Avez-vous besoin des conseils et de l'expertise pour l’amélioration de  la prise en charge de vos clients via votre call center ? Souhaitez-vous faire des push sms pour relancer, alerter et faire de la publicité à vos clients?  Voulez-vous être revendeur de SMS ? Voulez-vous un outil adapté pour gérer des sous-comptes, faire des push avancés, assurer la veille de  votre activité de SMS et  contrôler vos recharges et celles de vos clients ?  ",
    "hero.subtitle2":
      "Si oui, Univers Sarl est  une  entreprise spécialisée dans le domaine de la téléphonie ayant une  équipe d’experts dynamique et chevronnée prêts à vous servir au-delà de vos besoins et attentes.",

    "univers.value":
      "{path} est une entreprise spécialisée dans le  centre d'appels (call center) créée en Janvier 2021 dans le but de fournir  un excellent service  de qualité aux personnes utilisant des services de finance mobile. L’entreprise a  aussi étendu son activité à d’autres secteurs  afin de soutenir une clientèle toujours plus exigeante et soucieuse de la qualité des services offerts. Nous avons pour principe fondamental de répondre efficacement aux besoins de nos clients en tenant compte de leurs besoins et attentes.",
    ethics: "L’éthique",
    "ethics.content":
      "Valeurs morales absolues sur laquelle nous fondons notre activité (loyauté envers les clients et les collaborateurs, qualité supérieure en toutes circonstances)",
    respect: "Le respect",
    "respect.content":
      "Respect des clients, des lois, des ressources et des règles",
    excellence: "L’excellence",
    "excellence.content":
      "L’excellence se traduit dans l’optique que nous offrons à nos clients un service de qualité fiable et performant  et dans le choix rigoureux de nos ressources humaines, logicielles et matérielles.",

    wedo: "Ce que nous faisons",
    "wedo.content":
      "{path} est une entreprise spécialisée dans le centre d'appels (call center) située dans la ville de Douala. Elle offre un service d’aide et d’assistance à la clientèle intervenant avant, durant et après l’acte d’achat. Nous assurons la centralisation de toutes les informations sur le parcours client pour améliorer la collaboration en interne. Nous fournissons aussi des réponses adaptées et personnalisées à chaque interlocuteur en contact avec votre entreprise.",
    "career.title": "Désirez-vous développer votre talent avec nous ?",

    "overview.content":
      "Nous sommes un centre d'appels fiable et en pleine expansion qui travaille avec des clients au Cameroun et à l’international. Nous  offrons des services professionnels tels que l'enrichissement des données, le marketing direct par e-mail, la génération de leads, les études de marché et la prise de rendez-vous afin d'aider les entreprises à se développer et à atteindre leurs objectifs.  Les autres services que nous offrons sont appels entrants et sortants, la réponse directe, la collecte de fonds, la gestion des médias sociaux, le développement Web, le scoring d'appels, la saisie de données, le marketing par courriel, les enquêtes et le chat en direct... Nous pouvons aussi apporter une valeur ajoutée à votre entreprise, qu'il s'agisse d'une petite entreprise à petit budget ou d'une moyenne ou grande entreprise. Nous proposons  des plans de tarification souples et personnalisés qui sont garantis pour votre entreprise.)",
    overview: "Vue d'ensemble",
    "contact.center.content":
      "Nous disposons d’un centre de contacts omnicanal de 20 positions extensibles, utilisant l’une des meilleures technologies de traitement des appels Dinomi ...",
    "contact.center": "Centres de contact",
    "sms.push": "SMS (Push sms)",
    "sms.push.content":
      "Nous vous offrons un service de qualité incluant une supervision en temps réel des envois (statut des messages) et plusieurs types d’interco: SMPP, HTTPS, API et FTP",
    "special.number": "Numéros spéciaux (courts / Longs)",
    "special.number.content":
      "Nos ressources en numérotation sont reparties en deux grandes catégories : Les codes USSD et les numéros spéciaux (VERT, CLASSIQUE)",
    "rent.call": "centre d'appels",
    "rent.call.content":
      "Une solution de traitements et de prise en charge d’appels. Nous vous offrons ainsi la possibilité d’acquérir un volume d’appels selon vos besoins.",
    "contact.question": "Contactez-nous si vous avez des questions",
    "contact.partner": "Ou si intéressés par l'établissement d'un partenariat.",
    "client.review": "Avis de nos clients",
    citation:
      "Si nous ne prenons pas soin de nos clients, quelqu'un d'autre le fera !!!",
    // navbar
    about: "A propos",
    services: "Nos offres",
    contact: "Contact",
    blog: "Blog",
    career: "Carrière",

    // stats
    "happy.client": "CLIENTS HEUREUX",
    "service.provide": "FOURNISSEUR DE SERVICES",
    "project.completed": "PROJET TERMINÉ",
    "teams.members": "MEMBRES DE L'ÉQUIPE",

    // about

    "aboutus.content":
      "UNIVERS Sarl est une entreprise spécialisée dans le centre d'appels (call center) créée en Février 2021 dans le but de fournir un excellent service de qualité aux personnes utilisant des services de finance mobile. L’entreprise a aussi étendu son activité à d’autres secteurs afin de soutenir une clientèle toujours plus exigeante et soucieuse de la qualité des services offerts. Nous avons pour principe fondamental de répondre efficacement aux besoins de nos clients en tenant compte de leurs besoins et attentes.",
    whyus: "Pourquoi nous choisir",
    goal: "Votre objectif, notre réussite",
    mission: "La Mission",
    "mission.content":
      "Concevoir des solutions omnicanal de gestion de la relation client qui permettent aux organisations d’accompagner leurs clients avec chaleur et professionnalisme",
    engagement: "L'engagement",
    "engagement.content":
      "UNIVERS est une équipe de professionnels qui s’adresse à des clients qui tirent le plein potentiel de leurs solutions innovantes avec assurance et satisfaction.",

    vision: "Notre vision",
    "vision.content": "Etre leader de la relation client en Afrique.",
    promise: "Promise",
    "promise.content": "Nous simplifions la vie de vos clients.",
    ourstats: "Nos statistiques",
    //
    support: "Assistance 24/7",
    Language: "Langue",
    home: "Accueil",
    ousrServices: "Nos Services",
    career: "Carrière",
    happyClients: "CLIENTS SATISFAITS",
    serivesProviding: "PRESTATION DE SERVICES",
    projectCompleted: "PROJETS ACHEVÉS",
    teamMembers: "MEMBRES DE L'ÉQUIPE",
    welcomeUnivers: "Bienvenue dans le site web de UNIVERS SARL",
    specialNumber: "Numéros spéciaux ( Courts/longs)",
    "contact.center": "Centre de contacts",
    "seo.consult": "Conseil en Optimisation des Médias Sociaux",
    "graphic.design": "Infographie",
    "business.consult": "Conseil en entreprise",
    connectwithus: "Connectez-vous avec nous",
    address: "Adresse",
    "right.reserved": "Tous droits réservés",
    support: "Assistance",
    "find.teams": "Trouver l'équipe idéale",
    "more.info": "Pour plus d'informations, contactez-nous",
    "ours.jobs": "Nos emplois",
    "our.location": "Notre localisation",
    phone: "Numéro de téléphone",
    "email.address": "Adresse e-mail",
    "contact.form": "Formulaire de contact",
    "y.name": "Votre nom",
    "y.email": "Votre adresse e-mail",
    "y.phone": "Votre téléphone",
    "y.msg": "Votre message",
    "send.msg": "Envoyer un message",
    "base.services": "Les services de base",
    "base.services.sub":
      "UNIVERS Sarl offre un service qui favorise l’accompagnement du client à chaque stade de la vente pour une expérience de qualité.",
    "overview.subt": "La société Univers Call Center (Centre d’appels)",
    "words.abtus": "Quelques mots sur nous",
    "how.work": "COMMENT ÇA MARCHE ?",
    "check.process": "Consultez notre processus de travail !",
    "choose.service": "Choisir un service",
    "request.meet": "Demande d'entretien",
    "custom.plan": "Recevez un plan personnalisé",
    makeit: "Réalisons-le",
    "seo.consult": "Conseil en Optimisation des Médias Sociaux",
  },
};
