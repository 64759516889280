import React from "react";
import { Link } from "react-router-dom";
import translate from "../i18n/translate";

// import logo
import Logo from "../assets/img/logo.png";
// import { useTranslation } from "react-i18next";

// import data
import { features, navigation, servicesContent, siteInfo } from "../dataText";

const Footer = ({ loadingHandler }) => {
  const { subtitle } = features.feature2;

  return (
    <>
      <footer className="text-center lg:text-left w-full  pt-2 p-4 bg-primary text-white">
        {/*  */}
        <div className="xl:w-[80%] mx-auto">
          {" "}
          {/*  */}
          <div className="mx-6 pt-4 text-center md:text-left">
            <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="">
                {/* <div className="  p-1.5 rounded-full"> */}
                <img
                  className="h-14 lg:h-16 bg-white rounded-full p-1 mb-4"
                  src={Logo}
                  alt=""
                />
                {/* </div> */}
                <p className="text-lg">{subtitle}</p>
              </div>
              <div className=" ">
                <h6 className="uppercase font-bold text-lg mb-4 flex justify-center text-white md:justify-start">
                  {translate("ourservices")}
                </h6>
                {servicesContent.servicesList.map((item, index) => (
                  <p
                    key={index}
                    className="text-white flex items-center hover:text-gray-100 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span className="text-lg font-normal ml-4">
                      {item.title}
                    </span>
                  </p>
                ))}
              </div>

              <div className=" ">
                <h6 className="uppercase font-semibold mb-4 text-lg text-white flex justify-center md:justify-start ">
                  {translate("uselinks")}
                </h6>
                {navigation.map((item, index) => (
                  <p
                    key={index}
                    className="text-white hover:text-gray-100 cursor-pointer"
                    onClick={loadingHandler}
                  >
                    <Link
                      className="text-white hover:text-white"
                      to={item.href}
                    >
                      {item.name}
                    </Link>
                  </p>
                ))}
              </div>
              <div>
                <div className="">
                  <div className=" my-2  ">
                    <span className="text-lg">{translate("connectus")} </span>
                  </div>
                  <div className="flex mx-auto">
                    <p className="mr-6 text-white  py-3 px-3.5   border-2 border-white rounded-full    hover:text-white hover:bg-blue-600 hover:border-blue-600 cursor-pointer">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="facebook-f"
                        className="w-2.5"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="currentColor"
                          d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                        ></path>
                      </svg>
                    </p>{" "}
                    <p className="mr-6 text-white  p-3  border-2 border-white rounded-full    hover:text-white hover:bg-blue-400 hover:border-blue-400 cursor-pointer">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="twitter"
                        className="w-4"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        ></path>
                      </svg>
                    </p>
                    <p className="mr-6 text-white  p-3  border-2 border-white rounded-full    hover:text-white hover:bg-red-600 hover:border-red-600 cursor-pointer">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="google"
                        className="w-3.5"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 488 512"
                      >
                        <path
                          fill="currentColor"
                          d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                        ></path>
                      </svg>
                    </p>
                  </div>
                  {/*  */}
                  <div className="">
                    {/* <h6 className="uppercase font-semibold text-lg text-white mb-4 flex justify-center md:justify-start ">
                    Contact
                  </h6> */}
                    <div className="flex ">
                      <p className="flex items-center justify-center md:justify-start mb-4 cursor-pointer">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="home"
                          className="w-6 mr-4 mt-3 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                        >
                          <path
                            fill="currentColor"
                            d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                          ></path>
                        </svg>
                      </p>
                      <div className="border-l-2 border-white pl-4">
                        <p className="m-0"> {translate("address")} :</p>
                        <p className="m-0 text-gray-200">
                          {" "}
                          {siteInfo.location}
                        </p>
                      </div>
                    </div>{" "}
                    <div className="flex my-2">
                      <p className="flex items-center justify-center md:justify-start mb-4 cursor-pointer">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="envelope"
                          className="w-6 mr-4 mt-3 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                          ></path>
                        </svg>
                      </p>
                      <div className="border-l-2 border-white pl-4">
                        <p className="m-0"> {translate("emailus")} :</p>
                        <p className="m-0 text-gray-200"> {siteInfo.email}</p>
                      </div>
                    </div>
                    <div className="flex my-2">
                      <p className="flex items-center justify-center md:justify-start mb-4 cursor-pointer">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="phone"
                          className="w-6 mr-4 mt-3 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                          ></path>
                        </svg>
                      </p>
                      <div className="border-l-2 border-white pl-4">
                        <p className="m-0"> {translate("callus")}:</p>
                        <p className="m-0 text-gray-200">{siteInfo.phone} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center p-6 border-t-2  border-white">
          <span>
            {" "}
            &copy; UNIVERS Sarl 2022 - {translate("right.reserved")}.
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
