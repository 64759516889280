import React from "react";
import Formulaire from "../components/Formulaire";
import { DashSvg, ourJobs } from "../data";
// import Support from "./../components/Support";

export default function Career() {
  return (
    <div className="mb-12">
      <section className=" h-full max-h-[850px] w-full bg-career bg-right lg:bg-center bg-cover bg-no-repeat text-white  relative mb-12 lg:mb-28 ">
        <div className="w-full h-[650px] p-4 bg-gradient-to-r from-black  to-transparent ">
          <div class="w-full mt-48 lg:w-1/2">
            <div class=" pt-150 lg:pt-0 ml-24">
              <h2 class=" text-2xl text-white sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl font-extrabold">
                <span class="text-primary">Career</span>
              </h2>
              <div className="h-2 w-24 rounded-full bg-white"></div>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <div className="relative grid md:grid-cols-2 xl:w-[70%] mx-auto  gap-y-4 m-4 ">
        <p className="absolute -top-14 -right-20 z-0 hidden md:block">
          {DashSvg}{" "}
        </p>
        <div className="bg-white hidden md:block my-auto">
          {" "}
          <img src="/assets/images/hire.jpg" className="" alt="" />{" "}
        </div>
        <div className="z-[1] bg-white p-4">
          <p className="text-2xl text-primary">Find Your Opportunity</p>
          <p className="text-lg">
            Join Team of Professionals - Become a Part of Univers Call Center
            Company
          </p>
          <p className="font-light">
            Our team handles a vast array of contacts such as inbound phone
            support, chat and email for a wide variety of clients. We are unique
            in our culture, and promote a friendly, team-driven environment. Our
            company is always seeking qualified applicants to work for our
            growing companies providing world class customer support. Our scope
            of services range from retail to technical support.
          </p>
          <div className="mt-16 flex items-center gap-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-24 w-24 text-orange-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="m-0">
              Merci de déposer vos CV à la guérite d'Univers sis Logpom,
              immeuble vert en face du lions club ou envoyer par mail à
              l’adresse lorem@lorem.com au plus tard 2 à 17h.
            </p>
          </div>
          <button className=" p-2 m-2 duration-500  text-black bg-white hover:text-white  rounded-full border-primary    border hover:border-primary hover:bg-primary backdrop-blur-md transition">
            Postuler en ligne
          </button>
        </div>
      </div>
      {/*  */}
      <div className="xl:w-[80%] mx-auto mt-16 ">
        <p className="text-2xl text-primary text-center">Looking for Profile</p>
        <div className=" flex my-12">
          <button className=" p-4 m-2 text-white bg-primary  text-xl rounded-full   border hover:border-primary w-80 backdrop-blur-md transition">
            Customer Support Specialist
          </button>{" "}
          <button className=" p-4 m-2 text-black bg-white hover:text-white text-xl rounded-full border-primary  w-72  border hover:border-primary hover:bg-primary backdrop-blur-md transition">
            Sale Manager
          </button>
        </div>

        <div className="grid md:grid-cols-6">
          <div className="col-span-2  my-auto hidden md:block">
            <img className="" src="/assets/images/quality.jpg" alt="" />
          </div>
          <div className="col-span-4 ml-8">
            <p className="text-lg"> Customer support specialist</p>
            <p className="text-primary">Core Requirements: </p>
            <div>
              <p className="md:flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-2 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Experience or strong interest in modern technologies,
                distributed ledger technology and cryptocurrencies;
              </p>{" "}
              <p className="md:flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Higher education, preferably in Finance or Business
                administration;
              </p>{" "}
              <p className="md:flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Experience within customer service or finance industry;
              </p>{" "}
              <p className="md:flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Analytical and logical mindset, supplemented with high level of
                empathy;
              </p>{" "}
              <p className="md:flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                High level English skills;
              </p>{" "}
              <p className="md:flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Strong will to learn more and motivated by a fast-paced and
                international working environment;
              </p>{" "}
              <p className="md:flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Possibility to work in shifts, including weekend and night
                shifts;
              </p>{" "}
              <p className="md:flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-primary mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Possibility to work in shifts, including weekend and night
                shifts;
              </p>
              <p className="text-lg">
                For more information please{" "}
                <span className="text-primary">contact us</span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      <div>
        <p className="text-primary text-3xl text-center my-16">Our jobs</p>
        <div className="bg-hiring bg-center bg-cover bg-no-repeat bg-fixed">
          <div className="bg-black/70">
            <div className="grid md:grid-cols-2 p-4 lg:w-3/5 gap-2">
              {ourJobs.map((item) => (
                <div className=" bg-white/70 rounded-xl p-2 border-2 border-primary ">
                  <p className="text-white font-bold text-lg flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {item.title}
                  </p>
                  <div className="ml-4">
                    {item.content.map((item) => (
                      <p className="text-black font-bold  flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-2 text-white"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {item.title}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="grid lg:grid-cols-2 my-12 lg:w-[80%] mx-auto">
        <div className="p-4 ">
          <p className="text-primary text-lg">Candidature Spontanée</p>
          <Formulaire />{" "}
        </div>
        <div className="p-8 hidden  lg:block ">
          <img src="/assets/images/resume.jpg" alt="" className="h-full" />{" "}
        </div>
      </div>
    </div>
  );
}
